var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_job_costing") } },
        [
          _c(
            "span",
            {
              staticClass: "ant-form-text",
              attrs: { slot: "extra" },
              slot: "extra"
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("lbl_carton_unload")) + " "),
              _c("a-divider", { attrs: { type: "vertical" } }),
              _vm._v(" " + _vm._s(_vm.formModel.jobCostingNumber || "-") + " "),
              _c("a-divider", { attrs: { type: "vertical" } }),
              _vm._v(" " + _vm._s(_vm.formModel.journalNumber || "-") + " ")
            ],
            1
          ),
          !_vm.isModeView
            ? [
                _c(
                  "a-alert",
                  {
                    attrs: {
                      message: _vm.jcStart ? "" : _vm.$t("lbl_warning"),
                      type: _vm.jcStart ? "success" : "warning",
                      "show-icon": ""
                    }
                  },
                  [
                    _c("template", { slot: "description" }, [
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.jcStart
                                ? _vm.$t("lbl_job_costing_started")
                                : _vm.$t("lbl_you_have_not_start_job_costing")
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "div",
                        {},
                        [
                          !_vm.jcStart
                            ? _c(
                                "a-button",
                                {
                                  attrs: { block: "", type: "primary" },
                                  on: { click: _vm.startJobCosting }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_start")) + " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ],
                  2
                ),
                _c("a-divider")
              ]
            : _vm._e(),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model",
                    {
                      ref: "formJobCostingLoaf",
                      attrs: { model: _vm.formModel, rules: _vm.formRules }
                    },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16], type: "flex" } },
                        [
                          _c("a-col", { attrs: { span: 24 } }, [
                            _c("p", { staticClass: "text-subtitle-1" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_process_detail")) + " "
                              )
                            ])
                          ]),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_branch"),
                                    prop: "branchId"
                                  }
                                },
                                [
                                  _vm.isModeView
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.formModel.branch || "-")
                                        )
                                      ])
                                    : _c("CSelectBranch", {
                                        model: {
                                          value: _vm.formModel.branchId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formModel,
                                              "branchId",
                                              $$v
                                            )
                                          },
                                          expression: "formModel.branchId"
                                        }
                                      })
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_process_in") } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("time")(_vm.formModel.processIn)
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: { label: _vm.$t("lbl_process_date") }
                                },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formModel.processDate
                                          ? _vm
                                              .moment(_vm.formModel.processDate)
                                              .format(_vm.DEFAULT_DATE_FORMAT)
                                          : "-"
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_process_out") } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("time")(_vm.formModel.processOut)
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_operator_name"),
                                    prop: "operatorName"
                                  }
                                },
                                [
                                  _c("a-input", {
                                    attrs: {
                                      "allow-clear": "",
                                      "read-only": "",
                                      placeholder: _vm.$t("lbl_type_here")
                                    },
                                    model: {
                                      value: _vm.formModel.operatorName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "operatorName",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.operatorName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        {
                          attrs: {
                            gutter: [16, 16],
                            type: "flex",
                            justify: "space-between",
                            align: "bottom"
                          }
                        },
                        [
                          _c("a-col", { attrs: { sm: 24, md: 12 } }, [
                            _c("p", { staticClass: "text-subtitle-1" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_product_detail")) + " "
                              )
                            ]),
                            _c(
                              "p",
                              { staticClass: "text-subtitle-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("lbl_consumed_product")) +
                                    " "
                                ),
                                _c("a-button", {
                                  attrs: {
                                    size: "small",
                                    type: "dashed",
                                    icon: "undo"
                                  },
                                  on: { click: _vm.handleReset }
                                })
                              ],
                              1
                            )
                          ]),
                          !_vm.isModeView
                            ? _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12 } },
                                [
                                  _c("CGenerateBatchInventory", {
                                    on: {
                                      "on-search": _vm.onSearchBatchNumber
                                    },
                                    model: {
                                      value: _vm.vmBatchNumber,
                                      callback: function($$v) {
                                        _vm.vmBatchNumber =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "vmBatchNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: { label: _vm.$t("lbl_product_code") }
                                },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(
                                      _vm._s(_vm.formModel.productCode || "-")
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_pack_date") } },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formModel.packDate
                                          ? _vm
                                              .moment(_vm.formModel.packDate)
                                              .format(_vm.DEFAULT_DATE_FORMAT)
                                          : "-"
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_location") } },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(
                                      _vm._s(_vm.formModel.locationName || "-")
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: { label: _vm.$t("lbl_product_name") }
                                },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(
                                      _vm._s(_vm.formModel.productName || "-")
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_qty") } },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toDecimalQty")(
                                          _vm.formModel.qty
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: { label: _vm.$t("lbl_batch_number") }
                                },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(
                                      _vm._s(_vm.formModel.batchNumber || "-")
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_uom") } },
                                [
                                  _c("span", { staticClass: "an-form-text" }, [
                                    _vm._v(_vm._s(_vm.formModel.uom || "-"))
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24 } },
                [
                  _c("p", { staticClass: "text-subtitle-1" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_product")) + " ")
                  ]),
                  !_vm.isModeView
                    ? _c(
                        "a-space",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "danger", icon: "delete" },
                              on: { click: _vm.deleteRow }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_delete_row")) + " "
                              )
                            ]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "plus",
                                disabled: !_vm.formModel.productCode
                              },
                              on: { click: _vm.addRow }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          !_vm.isModeView
            ? _c(
                "a-row",
                { attrs: { type: "flex", gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("a-alert", {
                        attrs: {
                          message: _vm.$t(
                            "lbl_batch_number_generate_after_submit"
                          ),
                          type: "info",
                          "show-icon": ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-table",
                    {
                      attrs: {
                        "data-source": _vm.dtSource,
                        pagination: {
                          showTotal: function(total) {
                            return _vm.$t("lbl_total_items", { total: total })
                          }
                        },
                        scroll: { x: "calc(900px + 50%)", y: 520 },
                        "row-selection": {
                          selectedRowKeys: _vm.selectedRowKeys,
                          onChange: _vm.onRowSelect
                        }
                      }
                    },
                    [
                      _c(
                        "a-table-column",
                        { key: "no", attrs: { "data-index": "no", width: 75 } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_number_short")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "productCode",
                          attrs: { "data-index": "productCode" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(_vm._s(record.productCode || "-"))
                                  ])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_product_code")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "productName",
                          attrs: { "data-index": "productName" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(_vm._s(record.productName || "-"))
                                  ])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_product_name")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "qty",
                          attrs: { "data-index": "qty", width: "190px" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  !_vm.isModeView
                                    ? [
                                        _c(
                                          "a-space",
                                          [
                                            _c("a-input-number", {
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "lbl_type_here"
                                                ),
                                                formatter: _vm.formatterNumber,
                                                parser: _vm.reverseFormatNumber,
                                                precision:
                                                  _vm.DECIMAL_PLACES_QTY,
                                                min: 0
                                              },
                                              model: {
                                                value: record.qty,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    record,
                                                    "qty",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "record.qty"
                                              }
                                            }),
                                            _c(
                                              "a-button",
                                              {
                                                attrs: { type: "dashed" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.showModalScale(
                                                      record
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("lbl_scale")
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: record.qty <= 0,
                                                expression: "record.qty <= 0"
                                              }
                                            ],
                                            staticClass:
                                              "validate-error ant-form-explain"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl_validation_required_error"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    : _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("toDecimalQty")(record.qty)
                                            )
                                          )
                                        ]
                                      )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_qty")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "uomId",
                          attrs: { "data-index": "uomId", width: "100px" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  !_vm.isModeView
                                    ? [
                                        _c("CSelectUomConv", {
                                          attrs: {
                                            "prop-product-id":
                                              record.productId || ""
                                          },
                                          model: {
                                            value: record.uomId,
                                            callback: function($$v) {
                                              _vm.$set(record, "uomId", $$v)
                                            },
                                            expression: "record.uomId"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !record.uomId,
                                                expression: "!record.uomId"
                                              }
                                            ],
                                            staticClass:
                                              "validate-error ant-form-explain"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl_validation_required_error"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    : _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [_vm._v(_vm._s(record.uom || "-"))]
                                      )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_uom")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "locationId",
                          attrs: { "data-index": "locationId" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  !_vm.isModeView
                                    ? [
                                        _c("CSelectRack", {
                                          model: {
                                            value: record.locationId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                record,
                                                "locationId",
                                                $$v
                                              )
                                            },
                                            expression: "record.locationId"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !record.locationId,
                                                expression: "!record.locationId"
                                              }
                                            ],
                                            staticClass:
                                              "validate-error ant-form-explain"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl_validation_required_error"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    : _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [_vm._v(_vm._s(record.location || "-"))]
                                      )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_location")))]
                          )
                        ]
                      ),
                      _vm.isModeView
                        ? _c(
                            "a-table-column",
                            {
                              key: "batchNumber",
                              attrs: { "data-index": "batchNumber" }
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(_vm.$t("lbl_batch_number")))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c("a-col", { attrs: { align: "end", span: 24 } }, [
                _c(
                  "table",
                  {
                    staticClass: "ml-auto",
                    attrs: { "aria-labelledby": "table-summary" }
                  },
                  [
                    _c("thead", [_c("th", { attrs: { colspan: "2" } })]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("lbl_total_loaf_qty")) + ":")
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm._f("toDecimalQty")(_vm.sumLoafQty())) +
                              " KG"
                          )
                        ])
                      ]),
                      _c(
                        "tr",
                        {
                          class: {
                            "error-difference": _vm.countDiffLoafQty() > 0
                          }
                        },
                        [
                          _c("td", [
                            _vm._v(_vm._s(_vm.$t("lbl_qty_difference")) + ":")
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toDecimalQty")(_vm.countDiffLoafQty())
                              ) + " KG"
                            )
                          ])
                        ]
                      ),
                      _c("tr", [
                        _c("td", { staticClass: "align-baseline" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_difference_reason")) + ": "
                          )
                        ]),
                        _c(
                          "td",
                          [
                            _c("CSelectLoafReason", {
                              staticStyle: { width: "200px" },
                              attrs: { disabled: !_vm.isDifference },
                              model: {
                                value: _vm.formModel.differenceReason,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formModel,
                                    "differenceReason",
                                    $$v
                                  )
                                },
                                expression: "formModel.differenceReason"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.isDifference &&
                                    !_vm.formModel.differenceReason,
                                  expression:
                                    "isDifference && !formModel.differenceReason"
                                }
                              ],
                              staticClass: "validate-error ant-form-explain"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("lbl_validation_required_error")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ])
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleBack } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _vm.isModeView
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                icon: "printer",
                                type: "primary",
                                loading: _vm.loading.print
                              },
                              on: { click: _vm.handlePrint }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                          )
                        : _vm._e(),
                      !_vm.isModeView
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "save",
                                disabled: _vm.isDisabledSubmit,
                                loading: _vm.loading.submit
                              },
                              on: { click: _vm.handleSubmit }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm.modal.scale.show
        ? _c("CScale", {
            on: { "on-save": _vm.onScaleSave },
            model: {
              value: _vm.modal.scale.show,
              callback: function($$v) {
                _vm.$set(_vm.modal.scale, "show", $$v)
              },
              expression: "modal.scale.show"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }