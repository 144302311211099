





































































































































































































































































































































































































































































































import Vue from "vue";
import moment, { Moment } from "moment";
import {
  formatterNumber,
  reverseFormatNumber,
  letterKeydown,
} from "@/validator/globalvalidator";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "@/models/constant/date.constant";
import { ResponseBatch } from "@interface/batch.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseListProduct } from "@interface/product.interface";
import { productService } from "@service/product.service";
import { jobCostingService } from "@service/job-costing.service";
import {
  RequestJobCostingLoafCreate,
  ResponseJobCostingConsumeProductLoafDetail,
  ResponseJobCostingLoafDetail,
} from "@interface/job-costing-loaf.interface";
import { IGenericResponsePost } from "@interface/common.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { inventoryLineBatchService } from "@service/inventory-line-batch.service";
import { ResponseListInventoryLineBatch } from "@interface/inventory-line-batch.interface";
import { Mode } from "@/models/enums/global.enum";
import { batchService } from "@/services-v2/batch.service";
import printJS from "print-js";
import { mapGetters } from "vuex";
import localStorageService from "@/services/localStorage.service";
import { JOB_COSTING_LOAF_LOCAL_STORAGE } from "@/models/enums/job-costing.enum";
import {
  DECIMAL_PLACES_QTY,
  DEFAULT_PAGE_SIZE,
} from "@/models/constant/global.constant";
import { Decimal } from "decimal.js-light";

interface ITableRow {
  key: any;
  no: number;
  productCode: string;
  productName: string;
  productId: string;
  qty: number;
  uomId: string;
  uom: string;
  locationId: string;
  location: string;
  batchNumber: string;
}

export default Vue.extend({
  name: "JobCostingLoaf",
  components: {
    CSelectLoafReason: () =>
      import(
        /*webpackPrefetch: true */ "@/components/shared/select-loaf-reason/CSelectLoafReason.vue"
      ),
    CScale: () =>
      import(/*webpackPrefetch: true */ "@/components/shared/scale/CScale.vue"),
  },
  mixins: [MNotificationVue],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      DEFAULT_TIME_FORMAT,
      DECIMAL_PLACES_QTY,
      formModel: {
        processDate: this.moment() as Moment | null,
        processIn: null as Moment | null,
        processOut: "",
        operatorName: "",
        productCode: "",
        productName: "",
        productId: "",
        qty: 0,
        uom: "",
        uomId: "",
        batchNumber: "",
        batchNumberId: "",
        packDate: "",
        locationId: "",
        locationName: "",
        journalNumber: "",
        jobCostingNumber: "",
        differenceReason: undefined as string | undefined,
        branch: "",
        branchId: undefined as string | undefined,
      },
      formRules: {
        operatorName: [
          {
            required: true,
            trigger: "blur",
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        uomId: [
          {
            required: true,
            trigger: "change",
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        qty: [
          {
            required: true,
            type: "number",
            trigger: "change",
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        branchId: [
          {
            required: true,
            trigger: "change",
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
      },
      vmBatchNumber: "",
      selectedRowKeys: [] as string[],
      dtSource: [] as ITableRow[],
      dtOpt: {
        productCode: {} as ResponseListProduct,
      },
      loading: {
        submit: false,
        productCode: false,
        inventory: false,
        print: false,
      },
      jobCostingId: "",
      jcStart: false,
      modal: {
        scale: {
          data: {} as ITableRow,
          show: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getUsername: "authStore/GET_USERNAME",
    }),
    isDifference(): boolean {
      return this.countDiffLoafQty() !== 0;
    },
    isDisabledSubmit(): boolean {
      return this.isDifference && !this.formModel.differenceReason;
    },
    isModeView(): boolean {
      return this.$route.meta.mode === Mode.VIEW;
    },
  },
  watch: {
    getUsername(newValue): void {
      if (newValue && !this.isModeView) {
        this.formModel.operatorName = newValue;
      }
    },
  },
  created() {
    if (this.$route.params.id && this.isModeView) {
      this.jobCostingId = this.$route.params.id;
      this.fetchDetailJobCostingLoaf(this.jobCostingId);
    }
    if (!this.isModeView) {
      this.formModel.operatorName = this.getUsername;
      this.checkStartedJC();
    }
  },
  methods: {
    moment,
    formatterNumber,
    reverseFormatNumber,
    letterKeydown,
    showModalScale(r: ITableRow): void {
      this.modal.scale.data = r;
      this.modal.scale.show = true;
    },
    onScaleSave({ value }): void {
      const idx = this.modal.scale.data.key;
      this.dtSource[idx].qty = value;
    },
    printBatchs(batchNumber: string): Promise<ArrayBuffer> {
      return batchService.getPrintFile(batchNumber);
    },
    getDetailJobCostingLoaf(id: string): Promise<ResponseJobCostingLoafDetail> {
      return jobCostingService.getDetailJobCostingLoaf(id);
    },
    createJobCostingLoaf(
      payload: RequestJobCostingLoafCreate
    ): Promise<IGenericResponsePost> {
      return jobCostingService.createJobCostingLoaf(payload);
    },
    getListMasterProducts(
      params: RequestQueryParamsModel
    ): Promise<ResponseListProduct> {
      return productService.listProduct(params);
    },
    getListInventoryLineBatch(
      params: RequestQueryParamsModel
    ): Promise<ResponseListInventoryLineBatch> {
      return inventoryLineBatchService.getListInventoryLineBatch(params);
    },
    onRowSelect(rowKeys: string[]): void {
      this.selectedRowKeys = rowKeys;
    },
    sumLoafQty(): number {
      return this.dtSource.reduce(
        (prev, curr) => new Decimal(curr.qty).plus(prev).toNumber(),
        0
      );
    },
    handleBack(): void {
      this.$router.push({ name: "sales.transactionsales.jobcosting" });
    },
    countDiffLoafQty(): number {
      const diff = new Decimal(this.formModel.qty || 0)
        .minus(this.sumLoafQty() || 0)
        .toNumber();
      if (!diff) this.formModel.differenceReason = undefined;
      return diff;
    },
    addRow(): void {
      const { dtSource } = this;
      const newRow: ITableRow = {
        key: dtSource.length,
        no: dtSource.length + 1,
        productCode: this.dtOpt.productCode.data[0]?.code || "",
        productName: this.dtOpt.productCode.data[0]?.name || "",
        productId: this.dtOpt.productCode.data[0]?.id || "",
        locationId: "",
        location: "",
        qty: 0,
        uomId: "",
        uom: "",
        batchNumber: "",
      };
      this.dtSource = [...dtSource, newRow];
    },
    deleteRow(): void {
      const { dtSource } = this;
      this.dtSource = dtSource.filter(
        (data) => !this.selectedRowKeys.includes(data.key)
      );
      this.dtSource.forEach((x, i) => {
        x.key = i;
        x.no = i + 1;
      });
      this.selectedRowKeys = [];
    },
    onSearchBatchNumber({ batch }: { batch: ResponseBatch }): void {
      if (this.dtSource.length) {
        this.showConfirmation("notif_confirm_reset_loaf").then((confirm: boolean) => {
          if (!confirm) return;

          this.mapBatchToForm(batch);
        });
      } else {
        this.mapBatchToForm(batch);
      }
    },
    mapBatchToForm(batch: ResponseBatch): void {
      this.formModel.productCode = batch.productCode;
      this.formModel.productName = batch.productName;
      this.formModel.productId = batch.productId;
      this.formModel.batchNumber = batch.batchNumber;
      this.formModel.batchNumberId = batch.id;
      this.formModel.packDate = batch.packDate;
      this.formModel.uom = batch.baseUnit;
      this.formModel.uomId = batch.baseUnitId;
      this.formModel.qty = batch.qty;
      this.dtSource = [];
      this.searchProductCode(`${batch.productCode}#L`);
      this.searchInventoryLineBatch(batch.id);
      this.vmBatchNumber = "";
    },
    async searchProductCode(search = ""): Promise<void> {
      try {
        this.loading.productCode = true;
        const param: RequestQueryParamsModel = {
          limit: DEFAULT_PAGE_SIZE,
          page: 0,
          sort: "createdDate:desc",
        };
        if (search) param.search = `code~*${search}*`;
        const res = await this.getListMasterProducts(param);
        this.dtOpt.productCode = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.productCode = false;
      }
    },
    validateTable(): boolean {
      let valid = true;
      const { dtSource } = this;
      if (!dtSource.length) {
        valid = false;
      } else {
        const idx = dtSource.findIndex(
          (x) => x.qty <= 0 || !x.uomId || !x.locationId
        );
        if (idx !== -1) {
          valid = false;
        }
      }
      return valid;
    },
    handleSubmit(): void {
      const form = this.$refs.formJobCostingLoaf as any;
      form.validate((valid: boolean) => {
        if (valid && this.validateTable()) {
          this.createNewJobCostingLoaf();
        } else {
          this.showNotifValidationError();
        }
      });
    },
    handleReset(): void {
      this.showConfirmation("notif_confirm_reset_loaf").then(
        (confirm: boolean) => {
          if (!confirm) return;

          this.formModel.productCode = "";
          this.formModel.productName = "";
          this.formModel.productId = "";
          this.formModel.batchNumber = "";
          this.formModel.batchNumberId = "";
          this.formModel.packDate = "";
          this.formModel.locationId = "";
          this.formModel.locationName = "";
          this.formModel.uom = "";
          this.formModel.uomId = "";
          this.formModel.qty = 0;
          this.dtSource = [];
          this.vmBatchNumber = "";
        }
      );
    },
    async searchInventoryLineBatch(search = ""): Promise<void> {
      try {
        this.loading.inventory = true;
        const params: RequestQueryParamsModel = {
          limt: 1,
          page: 0,
          sorts: "createdDate:desc",
          search: `batch.secureId~${search}_AND_available>0`,
        };
        const res = await this.getListInventoryLineBatch(params);
        this.formModel.locationId = res.data.length
          ? res.data[0].warehouseLocationId
          : "";
        this.formModel.locationName = res.data.length
          ? res.data[0].warehouseLocationName
          : "";
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.inventory = false;
      }
    },
    async createNewJobCostingLoaf(): Promise<void> {
      try {
        this.loading.submit = true;
        const {
          processDate,
          processIn,
          operatorName,
          productId,
          qty,
          uomId,
          batchNumberId,
          packDate,
          locationId,
          differenceReason,
          branchId,
        } = this.formModel;
        const req: RequestJobCostingLoafCreate = {
          branchId: branchId || "",
          operationName: operatorName,
          processIn:
            processDate
              ?.set({
                hour: processIn?.hour(),
                minute: processIn?.minute(),
                second: processIn?.second(),
              })
              .utcOffset("+07")
              .format() || "",
          consume: {
            consumeProduct: {
              batchId: batchNumberId,
              locationId,
              packDate,
              productId,
              qty,
              uomId,
            },
            totalConsumeQTy: qty, // same as qty batch
          },
          produce: {
            differenceReason: differenceReason || "",
            produceProduct: this.dtSource.map((x) => {
              return {
                locationId: x.locationId,
                productId: x.productId,
                qty: x.qty,
                uomId: x.uomId,
              };
            }),
            totalDifference: this.countDiffLoafQty(),
            totalLoafQty: this.sumLoafQty(),
          },
        };
        const res = await this.createJobCostingLoaf(req);
        if (res.message) await this.showInfoModal(res.message);
        localStorageService.remove(JOB_COSTING_LOAF_LOCAL_STORAGE.PROCESS_IN);
        this.showSuccessMessage("notif_create_success");
        this.handleBack();
      } catch (error) {
        this.showErrorMessage("notif_create_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    async fetchDetailJobCostingLoaf(id: string): Promise<void> {
      try {
        const res = await this.getDetailJobCostingLoaf(id);
        this.formModel = {
          processDate: this.moment(res.processIn) || "",
          processIn: this.moment(res.processIn) || null,
          processOut: res.processOut,
          operatorName: res.operationName || "",
          productCode: res.consume.consumeProduct.productCode || "",
          productName: res.consume.consumeProduct.productName || "",
          productId: res.consume.consumeProduct.productId || "",
          qty: res.consume.consumeProduct.qty || 0,
          uom: res.consume.consumeProduct.uom || "",
          uomId: res.consume.consumeProduct.uomId || "",
          batchNumber: res.consume.consumeProduct.batchNumber || "",
          batchNumberId: res.consume.consumeProduct.batchNumberId || "",
          packDate: res.consume.consumeProduct.packDate || "",
          locationId: res.consume.consumeProduct.locationId || "",
          locationName: res.consume.consumeProduct.locationName || "",
          journalNumber: "",
          jobCostingNumber: res.jobCostingNumber || "",
          differenceReason: res.produce.differenceReason || "",
          branch: res.branchName,
          branchId: res.branchId,
        };
        this.fillDetailTable(res.produce.produceProduct);
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    fillDetailTable(
      products: ResponseJobCostingConsumeProductLoafDetail[]
    ): void {
      if (products.length) {
        let { dtSource } = this;
        dtSource = products.map((x, i) => {
          return {
            key: i,
            no: i + 1,
            productCode: x.productCode || "",
            productName: x.productName || "",
            productId: x.productId || "",
            qty: x.qty || 0,
            uomId: x.uomId || "",
            locationId: x.locationId || "",
            uom: x.uom || "",
            location: x.locationName || "",
            batchNumber: x.batchNumber || "",
          };
        });
        this.dtSource = [...dtSource];
      }
    },
    async handlePrint(): Promise<void> {
      try {
        if (!this.selectedRowKeys.length) {
          this.$notification.warning({
            description: this.$t("notif_choose_row").toString(),
            message: this.$t("lbl_warning_title").toString(),
          });
          return;
        }
        this.loading.print = true;
        const batchs: string[] = [];
        this.dtSource.forEach((x) => {
          if (this.selectedRowKeys.includes(x.key)) {
            batchs.push(x.batchNumber);
          }
        });
        const file = await this.printBatchs(batchs.join(","));
        const pdf = window.URL.createObjectURL(new Blob([file]));
        printJS({
          printable: pdf,
          onError: (error) => {
            this.$notification.error({
              message: this.$t("lbl_error_title").toString(),
              description: error.message,
            });
          },
        });
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.print = false;
      }
    },
    startJobCosting(): void {
      this.showConfirmation().then((val) => {
        if (val) {
          this.jcStart = true;
          localStorageService.save(
            JOB_COSTING_LOAF_LOCAL_STORAGE.PROCESS_IN,
            this.moment().format()
          );
          this.formModel.processIn = this.moment();
        }
      });
    },
    checkStartedJC(): void {
      const time = localStorageService.load(
        JOB_COSTING_LOAF_LOCAL_STORAGE.PROCESS_IN
      );
      if (!this.isModeView && time) {
        this.jcStart = true;
        this.formModel.processIn = this.moment(time);
      }
    },
  },
});
